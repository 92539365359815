<template>
  <div class="text-center">
    <v-dialog persistent v-model="progressBar" width="100px">
      <v-card-text style="height: 100px">
        <v-progress-circular :size="70" :width="7" color="black" indeterminate></v-progress-circular>
      </v-card-text>
    </v-dialog>

    <v-dialog persistent v-model="showpopup1" max-width="600" scrollable  >
          <v-container @click="this.gotoSpecials">
        
            <v-img :src="imageSrc"  :width="setwidth" :aspect-ratio="aspectRatio">
              <v-btn :ripple="false" icon id="no-background-hover"  absolute top right @click="showpopup1 = false">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
            </v-img>
      </v-container>
    </v-dialog>
    
    <v-snackbar v-model="snackbar" :timeout=4000 color="success">

      {{ snackbartext }}

    </v-snackbar>


    <nav v-if="shownavbar">
      <v-app-bar flat app dense :class="titlebarbackcolor">
        <v-app-bar-nav-icon :class="titlebaricocolor" @click="drawer = !drawer"
          v-if="isLarge !== true"></v-app-bar-nav-icon>
        <v-toolbar-title :class="titlebarstorenamecolor" v-if="NoLogoFound == true">{{
          StoreNameDisplay
        }}</v-toolbar-title>

        <v-toolbar-side-icon>
          <v-img :src="AppBarSrc" height="48px" :width="titlebarlogowidth" @error="imageUrlAlt">
          </v-img>
        </v-toolbar-side-icon>

        <v-spacer></v-spacer>

        <v-chip small v-bind:color="$store.state.Acart.length === 0 ? 'grey-light' : 'info'
          " @click="gotoMyCart">
          <v-icon left> mdi-cart </v-icon>
          {{ showcarttot }}
        </v-chip>
      </v-app-bar>
      <v-navigation-drawer v-model="drawer" app :class="menubackcolor" :permanent="isLarge">
        <v-list>
          <v-list-item v-for="link in adashlinks" :key="link.text" @click="link.method">
            <v-list-item-action>
              <v-icon :class="menuiconcolor">{{ link.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title :class="menutextcolor">
                {{ link.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </nav>

    <confirm ref="confirm"></confirm>
    <Signindialog ref="Signindialog"></Signindialog>

  </div>
</template>

<script>
import Confirm from "@/components/Confirm";
import Signindialog from "@/components/SignIn";
export default {
  components: { Confirm, Signindialog },
  data() {
    return {
      drawer: false,
      shownavbar: false,
      progressBar: false,
      showpopup1: false,
      snackbar: false,
      snackbartext: '',
      StoreName: "",
      BaseURL: "",
      StoreNameDisplay: "",
      NoLogoFound: false,
      AppBarSrc: "",
      Usertoken: "",
      adashlinks: [],
      titlebarbackcolor: "white",
      titlebarstorenamecolor: "black-text",
      titlebaricocolor: "white--text",
      titlebarlogowidth: "0px",
      menubackcolor: "indigo",
      menuiconcolor: "white--text",
      menutextcolor: "white--text",
      imageSrc: 'https://library.xdt.co.za/GetFileWithFalias?LibID=0&falias=Popup0',
      aspectRatio: 0,
      setwidth:0,
    }

  },


  watch: {
    "$store.state.issignedin"() {
      if (this.$store.state.issignedin == true) {
        this.dosnackbar('Signed In');
        this.SingedInMenu();
      } else {
        this.SingedOutMenu()
      }
    },

   
  },

  created() {
    this.StoreName = location.hostname; //this.$route.params.StoreName;

    this.BaseURL = location.protocol + "//" + location.hostname + ":" + location.port;

    switch (this.$route.name) {
      case 'ShowDoc':
        //alert(this.$route.params.bloburl);
        break;
      default:
        if (typeof this.StoreName == "undefined") {
          this.setundefinedstore();
        } else {
          this.refreshform();
        }
        break;
    }


  },

  computed: {
    showcarttot() {
      return this.$store.state.Acart.reduce(
        (sum, item) => sum + 1 * item.Qty,
        0
      );
    },


    isLarge(clicked) {
      if (clicked == true) {

        return !this.drawer
      } else { return this.$vuetify.breakpoint.name !== 'xs' && this.$vuetify.breakpoint.name !== 'sm' && this.$vuetify.breakpoint.name !== 'md' }
    },
  },

  mounted() {
    this.loadImage();
      },
  methods: {
    loadImage() {
      const img = new Image();
      img.src = this.imageSrc;
      
      img.onload = () => {
      
        //this.imageWidth = img.width;
        //this.imageHeight = img.height;

        this.setwidth = img.width;
        this.aspectRatio = img.width/img.height;
        if (this.$route.name == 'Home')
        {this.showpopup1 = true;}
      
      };

      },
     setundefinedstore() {
      this.StoreName = "undefined";
      this.StoreNameDisplay = "undefined";
      this.adashlinks = [];
      this.AppBarSrc = "";
    },

    SingedOutMenu() {
      this.adashlinks = [
//        {
//          icon: "home",
//          text: "Home",
//          method: () => this.routemethod("/Home/"),
//        },
//        {
//          icon: "mdi-information-outline",
//          text: "About us",
//          method: () => this.routemethod("/About/"),
//        },
        {
          icon: "storefront",
          text: "Online Store",
          method: () => this.routemethod("/Shop/"),
        },
        { icon: "mdi-login", text: "Sign in", method: () => this.SignIn() },
        {
          icon: "app_registration",
          text: "Register",
          method: () => this.routemethod("/Register/Register"),
        },
        {
          icon: "mdi-cart",
          text: "My Cart",
          method: () => this.routemethod("/MyCart/"),
        },
//        {
//          icon: "mdi-file-document-outline",
//          text: "SDS's & Specifications",
//          method: () => this.routemethod("/SdsSpecs/"),
//        },
//        {
//          icon: "mdi-certificate-outline",
//          text: "Certificate of Analysis",
//          method: () => this.routemethod("/Coa/"),
//        },
//        {
//          icon: "mdi-file-multiple-outline",
//          text: "Official Documents",
//          method: () => this.routemethod("/Documents/"),
//       },
//       {
//          icon: "mdi-card-account-phone-outline",
//          text: "Contact us",
//         method: () => this.routemethod("/Contactus/"),
//        },

      ];
    },

    SingedInMenu() {
      this.adashlinks = [
//        {
//          icon: "home",
//          text: "Home",
//          method: () => this.routemethod("/Home/"),
//        },
//        {
//          icon: "mdi-information-outline",
//          text: "About us",
//         method: () => this.routemethod("/About/"),
//        },
        {
          icon: "storefront",
          text: "Online Store",
          method: () => this.routemethod("/Shop/"),
        },
        { icon: "mdi-logout", text: "Sign out", method: () => this.SignOut() },
        {
          icon: "mdi-cart",
          text: "My Cart",
          method: () => this.routemethod("/MyCart/"),
        },
//        {
//          icon: "mdi-file-document-outline",
//          text: "SDS's & Specifications",
//          method: () => this.routemethod("/SdsSpecs/"),
//        },
//        {
//          icon: "mdi-certificate-outline",
//          text: "Certificate of Analysis",
//          method: () => this.routemethod("/Coa/"),
//        },
//        {
//          icon: "mdi-file-multiple-outline",
//          text: "Official Documents",
//          method: () => this.routemethod("/Documents/"),
//        },
//        {
//          icon: "mdi-card-account-phone-outline",
//          text: "Contact us",
//          method: () => this.routemethod("/Contactus/"),
//        },

      ];
    },

    async refreshform() {
      // Load Store details
      this.progressBar = true;
      this.myJsonResp = await this.PostData(
        this.StoreName,
        "LoadStoreParam",
        JSON.stringify({})
      );
      this.progressBar = false;
      //      if (typeof this.myJsonResp == "undefined") {
      //     this.myJsonResp='';
      //   }
      if (this.myJsonResp != "") {
        this.data = JSON.parse(this.myJsonResp);
        this.StoreNameDisplay = this.data.StoreNameDisplay;
        this.AppBarSrc =
          location.protocol +
          "//" +
          location.hostname +
          "/eSTore_API/sitelib/sysparams/MenuBarLogo.jpg";
        this.titlebarbackcolor = this.data.titlebarbackcolor;
        this.titlebarstorenamecolor = this.data.titlebarstorenamecolor;
        this.titlebaricocolor = this.data.titlebaricocolor;
        this.titlebarlogowidth = this.data.titlebarlogowidth;
        this.menubackcolor = this.data.menubackcolor;
        this.menuiconcolor = this.data.menuiconcolor;
        this.menutextcolor = this.data.menutextcolor;
        this.$store.dispatch("updateStoredetails", this.data.STOREDETAILS);
        this.$store.dispatch("updateDispCats", this.data.DispCats);
        this.shownavbar = true;
      } else {
        this.setundefinedstore();
        return;
      }
      document.title = this.StoreNameDisplay;
      this.Usertoken = sessionStorage.getItem(this.StoreName + "Usertoken");
      this.progressBar = true;
      this.myJsonResp = await this.PostDataNoErr(
        this.StoreName,
        "LoadUser",
        JSON.stringify(
          JSON.parse(
            JSON.stringify({
              Usertoken: this.Usertoken,
              ACART: this.$store.state.Acart,
            })
          )
        )
      );

      if (this.myJsonResp != "") {
        this.data = JSON.parse(this.myJsonResp);
        sessionStorage.setItem(
          this.StoreName + "Usertoken",
          this.data.Usertoken
        );
        this.$store.dispatch("updateUserdetails", this.data.USERDETAILS);
        this.$store.dispatch("updateAcart", this.data.ACART);
        if (this.data.Usertoken != "") {
          this.$store.dispatch("updateissignedin", true);
          this.SingedInMenu();
        } else {
          this.$store.dispatch("updateissignedin", false);
          sessionStorage.removeItem(this.StoreName + "Usertoken");
          this.$store.dispatch("updateUserdetails", null);

        }
      } else {
        this.$store.dispatch("updateissignedin", false);
        this.$store.dispatch("updateAcart", []);
        sessionStorage.removeItem(this.StoreName + "Usertoken");
        this.$store.dispatch("updateUserdetails", null);

      }
      this.progressBar = false;
    },

    SignIn() {
      this.$refs.Signindialog.open();
    },


    async SignOut() {
      this.progressBar = true;

      this.myJsonResp = await this.PostData(
        this.StoreName,
        "LoadUser",
        JSON.stringify(
          JSON.parse(
            JSON.stringify({
              Usertoken: "",
              ACART: this.$store.state.Acart,
            })
          )
        )
      );

      this.progressBar = false;

      if (this.myJsonResp != "") {
        this.data = JSON.parse(this.myJsonResp);
        this.$store.dispatch("updateAcart", this.data.ACART);
      } else {
        this.$store.dispatch("updateAcart", []);
      }

      sessionStorage.removeItem(this.StoreName + "Usertoken");

      this.$store.dispatch("updateUserdetails", null);

      this.$store.dispatch("updateissignedin", false);
      //this.dosnackbar('Signed Out');
    },

    routemethod(routeto) {
      this.$router.push(routeto);
    },

    dosnackbar(message) { this.snackbartext = message, this.snackbar = true },


    imageUrlAlt() { this.NoLogoFound = true; },

    gotoMyCart() {
      this.$router.push('/MyCart/')
    },

    gotoSpecials() {
      if (this.showpopup1 == false)
      {
        this.$router.push('/Home/');
      }
      else
      {
        this.showpopup1 = false;
        this.$router.push('/Specials/');
      }
    },

    
  },
};
</script>

