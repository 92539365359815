<template>
  <v-app class="grey lighten-4">
    <Navbar />
    <v-main class="mx-4 mb-4">
      <router-view> </router-view>
    </v-main>
  </v-app>
</template>



<script>
import Navbar from "@/components/Navbar";

export default {
  name: "App",
  components: { Navbar },
   // data: () => {
    //
  //},

  async mounted() {
    
    if (location.pathname != '/') { this.$router.push(location.pathname); }
    else { this.$router.push('/Shop/'); }
  },
  
  
};
</script>

