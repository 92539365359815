<template>
  <div class="Shop">
    <confirm ref="confirm"></confirm>

    <v-dialog persistent v-model="progressBar" width="100px">
      <v-card-text style="height: 100px">
        <v-progress-circular
          :size="70"
          :width="7"
          color="black"
          indeterminate
        ></v-progress-circular>
      </v-card-text>
    </v-dialog>

    <v-dialog scrollable v-model="editdialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Quantity - {{ SelectedCode }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container grid-list-md>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12">
                <div class="my-4 text-center">
                  <v-chip large @click="minuscart()">
                    <v-icon> mdi-minus </v-icon>
                  </v-chip>

                  <v-chip large>
                    <v-text-field
                      v-model="SelectedQty"
                      @focus="$event.target.select()"
                      ref="SelectedQty"
                      type="number"
                      min="1"
                      style="width: 100px"
                    ></v-text-field>
                  </v-chip>

                  <v-chip large @click="SelectedQty++">
                    <v-icon> mdi-plus </v-icon>
                  </v-chip>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="saveItem"> Update </v-btn>
          <v-btn color="blue darken-1" text @click="closeeditdialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container class="my-1">
      
      <v-card-text class="text-h4  pa-0 green--text text--darken-4">{{ PageTitle }}</v-card-text>
      <v-divider color="#1B5E20"></v-divider>
      <v-banner color="#ECEFF1">
        <v-banner-text class="text-body-2 pa-0">Plankomat's online store provides tailored solutions that cater to individual client needs and environments, leveraging our extensive range of hardware products to deliver comprehensive and integrated solutions.
        </v-banner-text>
      </v-banner>

      <v-card-text>
        <p></p>
      </v-card-text>


      <v-container class="my-1">
        <v-card class="pa-2 ma-5" color="grey lighten-2">
          <v-icon left> mdi-cart </v-icon>

          Cart Items
        </v-card>

        <v-card
          class="pa-2 ma-5"
          v-for="Cart in $store.state.Acart"
          :key="Cart.Code"
        >
          <v-row no-gutters>
            <v-layout row wrap>
              <v-col cols="12" sm="3" md="2">
                <div class="caption grey--text">Product Code</div>
                <div>{{ Cart.Code }}</div>
              </v-col>
              <v-col cols="12" sm="7" md="3">
                <div class="caption grey--text">Description</div>
                <div>{{ Cart.Description }}</div>
              </v-col>
              <v-col cols="6" sm="2" md="2" class="text-end">
                <div class="caption grey--text" v-if="ShowPriceIncl">
                  Price Incl.
                </div>
                <div class="caption grey--text" v-if="!ShowPriceIncl">
                  Price Excl.
                </div>
                <div v-if="Cart.Price == ''">On Request</div>
                <div v-if="ShowPriceIncl & (Cart.PriceIncl != 0)">
                  {{ DisplayCurrency(Cart.PriceIncl) }}
                </div>
                <div v-if="!ShowPriceIncl & (Cart.Price != 0)">
                  {{ DisplayCurrency(Cart.Price) }}
                </div>
              </v-col>
              <v-col cols="6" sm="3" md="1">
                <div class="caption grey--text">Unit</div>
                <div>{{ Cart.UnitOfMeasure }}</div>
              </v-col>
              <v-col cols="6" sm="7" md="1">
                <div class="caption grey--text">Qty</div>
                <div>{{ Cart.Qty }}</div>
              </v-col>
              <v-col cols="6" sm="2" md="2" class="text-end">
                <div class="caption grey--text">Total Incl.</div>
                <div>{{ DisplayCurrency(Cart.Qty * Cart.PriceIncl) }}</div>
              </v-col>
              <v-col cols="6" sm="6" md="1" class="text-end">
                <div class="caption grey--text">action</div>
                <v-icon small class="mr-2" @click="editItem(Cart)">
                  mdi-pencil
                </v-icon>
                <v-icon small @click="removeItem(Cart)"> mdi-delete </v-icon>
              </v-col>
            </v-layout>
          </v-row>
        </v-card>
        <v-card class="pa-2 ma-5" color="grey lighten-2">
          <v-row no-gutters>
            <v-layout row wrap>
              <v-col cols="12" sm="3" md="2">
                <v-icon left> mdi-sigma </v-icon>

                Totals
              </v-col>
              <v-col cols="12" sm="7" md="3"> </v-col>
              <v-col cols="6" sm="2" md="2" class="text-end"> </v-col>
              <v-col cols="6" sm="3" md="1"> </v-col>
              <v-col cols="6" sm="7" md="1">
                <div class="caption grey--text">Total Qty</div>
                <div>{{ totalqty }}</div>
              </v-col>
              <v-col cols="6" sm="2" md="2" class="text-end">
                <div class="caption grey--text">Cart Total Incl.</div>
                <div>{{ DisplayCurrency(totalprice) }}</div>
              </v-col>
              <v-col cols="6" sm="6" md="1" class="text-end"
                ><v-icon small @click="clearcart()"> mdi-delete </v-icon>
              </v-col>
            </v-layout>
          </v-row>
        </v-card>
      </v-container>

      <v-card class="pa-2 ms-5">
        <v-card-text v-if="this.Integration == 0">
          <v-container grid-list-md>
            <v-row no-gutters>
              <v-col cols="12" sm="5" md="5">
                <v-text-field
                  v-model="DebtorName"
                  ref="DebtorName"
                  label="Company Name or Account Name"
                  filled
                  dense
                  maxlength="100"
                  counter
                  :rules="Min6Char"
                  :disabled="$store.state.issignedin & (DebtorName == '')"
                  :readonly="$store.state.issignedin"
                ></v-text-field>
              </v-col>
              <v-col cols="0" sm="1" md="1"> </v-col>
              <v-col cols="12" sm="5" md="5">
                <v-text-field
                  v-model="DebtorID"
                  ref="DebtorID"
                  label="Account No"
                  filled
                  dense
                  readonly
                  v-if="$store.state.issignedin"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" sm="5" md="5">
                <v-text-field
                  v-model="ContactName"
                  ref="ContactName"
                  label="Contact Person (First Name and Last Name)"
                  filled
                  dense
                  maxlength="100"
                  counter
                  :rules="Min6Char"
                  :disabled="$store.state.issignedin & (ContactName == '')"
                  :readonly="$store.state.issignedin"
                ></v-text-field>
              </v-col>

              <v-col cols="0" sm="1" md="1"> </v-col>
              <v-col cols="12" sm="5" md="5">
                <v-text-field-simplemask
                  v-model="ContactNumber"
                  ref="ContactNumber"
                  label="Tel/Mobile number"
                  v-bind:properties="{
                    prefix: '+',
                    suffix: '',
                    disabled: $store.state.issignedin & (ContactNumber == ''),
                    readonly: $store.state.issignedin,
                    outlined: false,
                    clearable: true,
                    placeholder: '',
                  }"
                  v-bind:options="{
                    inputMask: '#############',
                    outputMask: '#############',
                    empty: null,
                    applyAfter: false,
                    alphanumeric: false,
                    lowerCase: false,
                  }"
                  dense
                ></v-text-field-simplemask>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" sm="5" md="5">
                <v-text-field
                  v-model="EmailAddress"
                  ref="EmailAddress"
                  label="Email Address"
                  filled
                  :rules="emailRules"
                  dense
                  maxlength="100"
                  counter
                  :disabled="$store.state.issignedin & (EmailAddress == '')"
                  :readonly="$store.state.issignedin"
                ></v-text-field>
              </v-col>
              <v-col cols="0" sm="1" md="1"> </v-col>
              <v-col cols="12" sm="5" md="5">
                <v-textarea
                  v-model="Shiptoaddress"
                  label="Ship to address"
                  ref="Shiptoaddress"
                  filled
                  dense
                  :disabled="$store.state.issignedin & (Shiptoaddress == '')"
                  :readonly="$store.state.issignedin"
                  v-if="this.Integration == 0"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row no-gutters> </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="SubmitCart()">
            {{ this.Process }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>


<script>
// @ is an alias to /src
import Confirm from "@/components/Confirm";
export default {
  components: { Confirm },
  data() {
    return {
      StoreName: "",
      SelectedCode: "",
      SelectedQty: 0,
      PageTitle: "My Cart",
      progressBar: false,
      editdialog: false,
      ShowPriceIncl: true,
      DebtorName: "",
      DebtorID: "",
      ContactName: "",
      ContactNumber: "",
      EmailAddress: "",
      Shiptoaddress: "",
      Integration: 0,
      Process: "",
      Min6Char: [
        (v) => (v && v.length > 5) || "A minimum of 6 characters required.",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
      ],
    };
  },

  watch: {
    "$store.state.issignedin"() {
      this.loaduserfromsession();
    },

    "$store.state.Storedetails"() {
      this.loadStoredetails();
    },

    "$store.state.Userdetails"() {
      this.loadUserdetails();
    },
  },

  computed: {
    totalprice: function () {
      //let sum = 0;
      return this.$store.state.Acart.reduce(
        (sum, item) => sum + item.Qty * item.PriceIncl,
        0
      );
    },

    totalqty: function () {
      //let sum = 0;
      return this.$store.state.Acart.reduce(
        (sum, item) => sum + 1 * item.Qty,
        0
      );
    },
  },

  created() {
    this.StoreName = location.hostname;
    this.refreshform();
  },

  methods: {
    async refreshform() {
      this.progressBar = true;
      this.loadUserdetails();
      this.loadStoredetails();
      this.progressBar = false;
    },

    loadUserdetails() {
      if (this.$store.state.Userdetails != null) {
        this.DebtorName = this.$store.state.Userdetails.DebtorName;
        this.DebtorID = this.$store.state.Userdetails.DebtorID;
        this.ContactName = this.$store.state.Userdetails.ContactName;
        this.ContactNumber = this.$store.state.Userdetails.ContactNumber;
        this.EmailAddress = this.$store.state.Userdetails.EmailAddress;
        this.Shiptoaddress = this.$store.state.Userdetails.Shiptoaddress;
      } else {
        this.DebtorName = "";
        this.DebtorID = "";
        this.ContactName = "";
        this.ContactNumber = "";
        this.EmailAddress = "";
        this.Shiptoaddress = "";
      }
    },

    loadStoredetails() {
      if (this.$store.state.Storedetails != null) {
        this.Integration = this.$store.state.Storedetails.Integration;
        this.ShowPriceIncl = this.$store.state.Storedetails.ShowPriceIncl;
        this.Process = this.$store.state.Storedetails.Process;
      }
    },

    async removeItem(pid) {
      this.SelectedProduct = pid;
      if (
        await this.$refs.confirm.open(
          "Remove",
          "Remove " + this.SelectedProduct.Code + " from Cart?",
          "Yes",
          "Cancel",
          { color: "error" }
        )
      ) {
        if (this.SelectedProduct.Code != "") {
          //if (ShowPriceIncl) {} else {};

          this.$store.dispatch("minusAcart", {
            Product: this.SelectedProduct,
            Qty: this.SelectedProduct.Qty,
          });
        }
      }
    },

    async clearcart() {
      if (
        await this.$refs.confirm.open(
          "Clear",
          "Clear all items from Cart?",
          "Yes",
          "Cancel",
          { color: "error" }
        )
      ) {
        this.$store.dispatch("updateAcart", []);
      }
    },

    editItem(pid) {
      this.SelectedProduct = pid;
      this.SelectedCode = this.SelectedProduct.Code;
      this.SelectedQty = this.SelectedProduct.Qty;
      this.editdialog = true;
    },

    async saveItem() {
      if (this.SelectedQty > 0) {
        if (this.SelectedProduct.Code != "") {
          //if (ShowPriceIncl) {} else {};

          this.$store.dispatch("plusAcart", {
            Product: this.SelectedProduct,
            Qty: this.SelectedQty - this.SelectedProduct.Qty,
          });
          document.activeElement.blur();
          this.editdialog = false;
          this.editdialog = false;
        }
      } else {
        await this.$refs.confirm.open(
          "Error",
          "A minimum quantity of 1 is required !",
          "",
          "Close",
          { color: "error" }
        );
      }
    },

    closeeditdialog() {
      this.editdialog = false;
    },

    minuscart() {
      if (this.SelectedQty > 1) {
        this.SelectedQty--;
      }
    },

    async SubmitCart() {
      if (this.$store.state.Acart.length < 1) {
        await this.$refs.confirm.open(
          "Error",
          "Your Cart is Empty. please proceed with shopping !",
          "",
          "Close",
          { color: "error" }
        );
        return;
      }

      // If Integration is 1 and not signed in then must register and quote
      if (this.$store.state.issignedin == false && this.Integration == 1) {
      this.$router.push("/Register/Quote");
      return;
      }

      // Post
      this.progressBar = true;
      this.myJsonResp = await this.PostData(
        this.StoreName,
        "SubmitCart",
        JSON.stringify(
          JSON.parse(
            JSON.stringify({
              Usertoken: sessionStorage.getItem(this.StoreName + "Usertoken"),
              DebtorName: this.DebtorName,
              ContactName: this.ContactName,
              ContactNumber: this.ContactNumber,
              EmailAddress: this.EmailAddress,
              Shiptoaddress: this.Shiptoaddress,
              ACART: this.$store.state.Acart,
            })
          )
        )
      );
      this.progressBar = false;

      if (this.myJsonResp != "") {
        this.data = JSON.parse(this.myJsonResp);

        if (this.data.isvalid === false) {
          await this.$refs.confirm.open(
            "Error",
            this.data.retmsg,
            "",
            "Close",
            { color: "error" }
          );

          setTimeout(() => {
            this.$refs["" + this.data.retfield + ""].focus();
          });
        } else {
          // Clear the Cart
          this.$store.dispatch("updateAcart", []);
          await this.$refs.confirm.open(
            "Success",
            this.data.retmsg,
            "",
            "Close",
            { color: "success" }
          );
          // Go to Shop
          this.$router.push("/Shop/");
        }
      }
    },
  },
};
</script>
